import React from 'react';
import loadable from '@loadable/component';

import FormFields from './FormFields/FormFields';
// import Display from './Display/Display';

const Display = loadable(() => import('./Display/Display'));

const Calculator = () => {
	return (
		<>
			<FormFields />
			<Display />
		</>
	);
};

export default Calculator;
